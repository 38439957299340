var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pl-5 pt-5"},[_c('v-checkbox',{attrs:{"label":'モチベーション選択時に、自動でALIVEの出社打刻を行う'},on:{"click":function($event){return _vm.aliveStampingChange(_vm.aliveStamping)}},model:{value:(_vm.aliveStamping),callback:function ($$v) {_vm.aliveStamping=$$v},expression:"aliveStamping"}})],1),_c('v-radio-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.aliveStamping),expression:"aliveStamping"}],staticClass:"pl-2 pt-0",attrs:{"row":""},model:{value:(_vm.chooseWork),callback:function ($$v) {_vm.chooseWork=$$v},expression:"chooseWork"}},[_c('v-radio',{attrs:{"label":"出社","color":"#1976d2","value":"office"},on:{"click":function($event){return _vm.offRadioMsg()}}}),_c('v-radio',{attrs:{"label":"在宅","color":"#1976d2","value":"home"},on:{"click":function($event){return _vm.offRadioMsg()}}})],1),(_vm.showRadioMsg)?_c('v-container',{staticClass:"red--text font-weight-bold"},[_c('p',[_vm._v("出社・在宅ボタンを選んでください。")])]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [[_c('v-card',{staticClass:"pa-md-4 mx-lg-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[[_c('v-img',{staticClass:"my-2 subheading",attrs:{"src":"https://d3e57be0v9mmli.cloudfront.net/verygood.png","height":"20vh","contain":""},on:{"click":function($event){return _vm.setStamp(_vm.stamp1)}}})]],2)]]}}])})],1),_c('v-col',{attrs:{"cols":"6","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [[_c('v-card',{staticClass:"pa-md-4 mx-lg-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[[_c('v-img',{staticClass:"my-2 subheading",attrs:{"src":"https://d3e57be0v9mmli.cloudfront.net/good.png","height":"20vh","contain":""},on:{"click":function($event){return _vm.setStamp(_vm.stamp2)}}})]],2)]]}}])})],1),_c('v-col',{attrs:{"cols":"6","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [[_c('v-card',{staticClass:"pa-md-4 mx-lg-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[[_c('v-img',{staticClass:"my-2 subheading",attrs:{"src":"https://d3e57be0v9mmli.cloudfront.net/normal.png","height":"20vh","contain":""},on:{"click":function($event){return _vm.setStamp(_vm.stamp3)}}})]],2)]]}}])})],1),_c('v-col',{attrs:{"cols":"6","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [[_c('v-card',{staticClass:"pa-md-4 mx-lg-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[[_c('v-img',{staticClass:"my-2 subheading",attrs:{"src":"https://d3e57be0v9mmli.cloudfront.net/bad.png","height":"20vh","contain":""},on:{"click":function($event){return _vm.setStamp(_vm.stamp4)}}})]],2)]]}}])})],1),_c('div',{staticClass:"col-12 pt-10"},[_c('v-card',{attrs:{"color":"basil","outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h2',[_vm._v("みんなのひとこと")])]),_c('v-row',{staticClass:"px-10 pb-7 pt-2 text-center justify-center tag"},_vm._l((_vm.tags),function(tag){return _c('span',{key:tag.name,staticClass:"px-1",style:("font-family: 'RiiPop';font-size:" + tag.fontSize + 'px; color:' + tag.color)},[_vm._v(_vm._s(tag.name))])}),0)],1)],1)],1),_c('dialog-stamp-total',{attrs:{"total":_vm.totalStamp,"alive_response":_vm.alive_response},on:{"close":_vm.closeDialog},model:{value:(_vm.showtotal),callback:function ($$v) {_vm.showtotal=$$v},expression:"showtotal"}}),_c('tag',{on:{"close":_vm.closeDialogTag},model:{value:(_vm.showTagDialog),callback:function ($$v) {_vm.showTagDialog=$$v},expression:"showTagDialog"}}),_c('aliveDateEdit',{attrs:{"dialogItems":_vm.dialogItems},on:{"close":_vm.closealiveDateEdit},model:{value:(_vm.showaliveDateEdit),callback:function ($$v) {_vm.showaliveDateEdit=$$v},expression:"showaliveDateEdit"}}),_c('ErrorMessage',{attrs:{"callback":_vm.callbackError}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }