<template>
  <v-row justify="center">
    <v-dialog v-model="value" persistent max-width="500">
      <v-card height="40vh" width="75vw" class="my-card" @click="closeDialog()">
        <v-card-actions style="position: absolute; right: 0"> </v-card-actions>
        <v-container fill-height fluid>
          <v-row align="center" justify="center">
            <v-card-text class="text-center">
              <div>
                <h3 class="text-h3 font-weight-bold" v-if="alive_response == 'aliveSuccess'">ALIVE打刻に成功しました</h3>
                <h3 class="text-h3 font-weight-bold" v-if="alive_response == 'aliveError'">ALIVE打刻に失敗しました<br />ALIVEのサイトから打刻をお願いします</h3>
              </div>
              <div>
                <h1 class="text-h1 font-weight-bold">{{ animatedNumber }}回達成</h1>
              </div>
            </v-card-text>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import gsap from 'gsap'
export default {
  props: {
    value: Boolean,
    total: Number,
    alive_response: String,
  },
  data() {
    return {
      tweenedNumber: 0,
    }
  },
  computed: {
    animatedNumber: function () {
      return this.tweenedNumber.toFixed(0)
    },
  },

  methods: {
    closeDialog() {
      this.$emit('close')
    },
  },
  watch: {
    total: function (newValue) {
      gsap.to(this.$data, { duration: 1, tweenedNumber: newValue })
    },
  },
}
</script>
