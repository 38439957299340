<template>
  <v-form ref="form">
    <v-row justify="center">
      <v-dialog v-model="value" persistent max-width="500">
        <v-card>
          <v-card-title class="text-h5"> 今日の意気込みをみんなに！ </v-card-title>
          <v-card-text>
            <v-text-field v-model="tag" outlined required :rules="[rules.required, rules.max]" counter="60" color="#ffde7b" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#3f51b5" class="white--text" @click="closeDialog()"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>
<script>
import { trim } from '@amcharts/amcharts4/.internal/core/utils/Utils'

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      tag: '',
      rules: {
        required: (v) => !!v || '必須入力です',
        max: (v) => v.length <= 60 || '最大60文字入力可能',
      },
    }
  },
  methods: {
    closeDialog() {
      if (trim(this.tag).length > 60) {
        this.$refs.form.validate()
        return
      }
      if (trim(this.tag).length === 0) {
        this.$refs.form.validate()
        return
      }
      this.$refs.form.validate()
      this.$emit('close', this.tag)
    },
  },
}
</script>
