<template>
  <div>
    <v-row class="pl-5 pt-5">
      <v-checkbox v-model="aliveStamping" @click="aliveStampingChange(aliveStamping)" :label="'モチベーション選択時に、自動でALIVEの出社打刻を行う'"> </v-checkbox>
    </v-row>
    <v-radio-group v-show="aliveStamping" v-model="chooseWork" class="pl-2 pt-0" row>
      <v-radio label="出社" color="#1976d2" value="office" @click="offRadioMsg()"></v-radio>
      <v-radio label="在宅" color="#1976d2" value="home" @click="offRadioMsg()"></v-radio>
    </v-radio-group>
    <v-container v-if="showRadioMsg" class="red--text font-weight-bold">
      <p>出社・在宅ボタンを選んでください。</p>
    </v-container>
    <v-row>
      <v-col cols="6" lg="3">
        <v-hover v-slot:default="{ hover }">
          <template>
            <v-card class="pa-md-4 mx-lg-auto" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <template>
                <v-img src="https://d3e57be0v9mmli.cloudfront.net/verygood.png" class="my-2 subheading" height="20vh" contain @click="setStamp(stamp1)" />
              </template>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="6" lg="3">
        <v-hover v-slot:default="{ hover }">
          <template>
            <v-card class="pa-md-4 mx-lg-auto" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <template>
                <v-img src="https://d3e57be0v9mmli.cloudfront.net/good.png" class="my-2 subheading" height="20vh" contain @click="setStamp(stamp2)" />
              </template>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="6" lg="3">
        <v-hover v-slot:default="{ hover }">
          <template>
            <v-card class="pa-md-4 mx-lg-auto" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <template>
                <v-img src="https://d3e57be0v9mmli.cloudfront.net/normal.png" class="my-2 subheading" height="20vh" contain @click="setStamp(stamp3)" />
              </template>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="6" lg="3">
        <v-hover v-slot:default="{ hover }">
          <template>
            <v-card class="pa-md-4 mx-lg-auto" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <template>
                <v-img src="https://d3e57be0v9mmli.cloudfront.net/bad.png" class="my-2 subheading" height="20vh" contain @click="setStamp(stamp4)" />
              </template>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <div class="col-12 pt-10">
        <v-card color="basil" outlined>
          <v-card-title primary-title>
            <h2>みんなのひとこと</h2>
          </v-card-title>
          <v-row class="px-10 pb-7 pt-2 text-center justify-center tag">
            <span v-for="tag in tags" :key="tag.name" class="px-1" :style="`font-family: 'RiiPop';font-size:` + tag.fontSize + 'px; color:' + tag.color">{{ tag.name }}</span>
          </v-row>
        </v-card>
      </div>
    </v-row>
    <dialog-stamp-total v-model="showtotal" :total="totalStamp" :alive_response="alive_response" @close="closeDialog" />
    <tag v-model="showTagDialog" @close="closeDialogTag" />
    <aliveDateEdit :dialogItems="dialogItems" v-model="showaliveDateEdit" @close="closealiveDateEdit" />
    <ErrorMessage :callback="callbackError" />
  </div>
</template>
<script>
import DialogStampTotal from './DialogStampTotal.vue'
import cnst from '@/const/const'
import { mapActions, mapState } from 'vuex'
import router from '@/router'
import Tag from './Tag.vue'
import { trim } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import moment from 'moment'
import aliveDateEdit from '../alive/aliveDateEdit.vue'
// import aliveHack from '../../views/alive/alive_hack.js'
// import { puppeteer } from 'puppeteer';

// import puppeteer from '@/puppeteer'

export default {
  components: {
    DialogStampTotal,
    Tag,
    aliveDateEdit,
    // aliveHack
  },

  data() {
    return {
      chooseWork: null,
      listTenant: [],
      tagsData: [],
      tags: [],
      showtotal: false,
      stamp1: cnst.ID_STAMP_1,
      stamp2: cnst.ID_STAMP_2,
      stamp3: cnst.ID_STAMP_3,
      stamp4: cnst.ID_STAMP_4,
      totalStamp: 0,
      alive_response: '',
      chooseStamp: '',
      showRadioMsg: false,
      showTagDialog: false,
      aliveStamping: false,
      showaliveDateEdit: false,
      dialogItems: {
        aliveId: '',
        alivePassword: '',
      },
    }
  },
  async mounted() {
    const input = {
      mail: localStorage.getItem(cnst.STORAGE_EMAIL),
      fromdate: moment().format('YYYY-MM-DD'),
    }
    await this.getTotalStampCompany(input)
  },
  methods: {
    ...mapActions('stamp', ['sendStamp', 'getStamp', 'retrieveSentiment', 'alive']),
    ...mapActions('home', ['getTotalStamp']),
    setStamp(stamp_id) {
      this.chooseStamp = stamp_id
      if (this.aliveStamping && this.chooseWork === null) {
        this.showRadioMsg = true
        return
      } else {
        this.showRadioMsg = false
      }
      this.showTagDialog = true
    },
    offRadioMsg() {
      this.showRadioMsg = false
    },
    ongetStamp() {
      const input = {
        mail: localStorage.getItem(cnst.STORAGE_EMAIL),
      }

      this.getStamp(input)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            this.showtotal = true
            this.totalStamp = res.data.total_stamp
            this.alive_response
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    closeDialog() {
      this.showtotal = false
      router.push({ path: '/home' })
    },
    callbackError() {},
    async closeDialogTag(tag) {
      this.showTagDialog = false
      // Alive打刻連携チェック有り時
      if (this.aliveStamping) {
        await this.alivestamp()
      }
      const input = {
        stamp_id: this.chooseStamp,
        tag: trim(tag),
        mail: localStorage.getItem(cnst.STORAGE_EMAIL),
      }
      this.sendStamp(input)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            this.ongetStamp()
          }
        })
        .catch((err) => {
          console.log(err)
          if (err.response.status === 404) {
            this.$store.commit(cnst.ERROR_CALLBACK, this.ongetStamp, { root: true })
            this.$store.commit(cnst.SHOW_LOADING, false, { root: true })
            this.$store.commit(cnst.ERROR, null, { root: true })
            setTimeout(() => {
              this.$store.commit(cnst.ERROR, '送金処理がエラーになりました。管理者へお問い合わせください。', { root: true })
            }, 100)
          }
        })
    },
    async getTotalStampCompany(input) {
      await this.getTotalStamp(input).then(() => {
        this.tagsData.length = 0

        this.listTag.forEach((element) => {
          let objData = {
            name: element.tag,
            count: element.total,
            message_positive_score: element.message_positive_score === null ? 0 : element.message_positive_score,
          }
          this.tagsData.push(objData)
        })

        if (this.tagsData.length > 0) this.getTagCloud(this.tagsData)
      })
    },
    getTagCloud(tags) {
      let maxSize = 28
      let minSize = 8

      // tags.countを降順にソート
      let copyTags = tags
      copyTags.sort((a, b) => {
        return b.message_positive_score - a.message_positive_score
      })

      // タグの表示数
      // let limits = copyTags.length < 5 ? copyTags.length : 5;
      let limits = copyTags.length

      let max = copyTags[0].message_positive_score
      let min = copyTags[limits - 1].message_positive_score

      if (min < 0) {
        let minTmp = -min
        for (let i = 0; i < limits; i++) {
          copyTags[i].message_positive_score += minTmp
        }
        max += minTmp
        min += minTmp
      }

      // フォントサイズを決定し、表示する分だけthis.tagsに格納
      let listColor = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf']
      this.tags = []
      for (let i = 0; i < limits; i++) {
        let perc = max === min ? 1 : (copyTags[i].message_positive_score - min) / (max - min)
        let size = Math.round((maxSize - minSize) * perc + minSize)

        this.tags[i] = copyTags[i]
        this.tags[i].fontSize = size

        let pos = i.toString().slice(i.toString().length - 1)
        this.tags[i].color = listColor[pos]
      }

      // tags.nameを昇順にソート
      this.tags.sort(function (a, b) {
        return (a.name > b.name) - (a.name < b.name)
      })
    },
    // ALIVE自動打刻チェックボックス処理
    aliveStampingChange(aliveStampingFlag) {
      if (!aliveStampingFlag) {
        localStorage.setItem('ALIVE_SET', 'false')
        return
      }
      this.dialogItems.aliveId = localStorage.getItem('ALIVE_ID')
      this.dialogItems.alivePassword = localStorage.getItem('ALIVE_PASSWORD')
      this.showaliveDateEdit = true
    },
    closealiveDateEdit(date) {
      if (date == 'cancel') {
        this.aliveStamping = false
      } else {
        localStorage.setItem('ALIVE_SET', 'true')
      }
      this.showaliveDateEdit = false
    },
    // 打刻実施
    async alivestamp() {
      var response = ''
      const input = {
        aliveId: localStorage.getItem('ALIVE_ID'),
        alivePassword: localStorage.getItem('ALIVE_PASSWORD'),
        chooseWork: this.chooseWork,
      }
      await this.alive(input)
        .then((res) => {
          if (res.data.errorMessage != undefined) {
            console.log('ALIVE timeout')
            response = 'aliveError'
          } else if (res.data == 'aliveError') {
            response = 'aliveError'
          } else if (res.data != '') {
            response = 'aliveSuccess'
          } else {
            response = 'aliveError'
          }
        })
        .catch((err) => {
          console.log(err)
          response = 'aliveError'
        })
      this.alive_response = response
      return
    },
  },
  computed: {
    ...mapState('home', ['listTag']),
  },

  async created() {
    if (localStorage.getItem('ALIVE_SET') == 'true') {
      this.aliveStamping = true
    } else {
      this.aliveStamping = false
    }
    const input = {
      mail: localStorage.getItem(cnst.STORAGE_EMAIL),
    }
    await this.getStamp(input)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.last_time != undefined) {
            let lasttime = moment(moment(res.data.last_time).format('YYYY-MM-DD'))
            if (moment(moment().format('YYYY-MM-DD')).diff(lasttime, 'days') == 0) {
              if (this.$route.path !== '/home') router.push({ path: '/home' })
            } else {
              if (this.$route.path !== '/stamp') router.push({ path: '/stamp' })
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
}
</script>
